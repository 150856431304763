import { Component, OnInit, Inject } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { GenericViewForm } from '@shoobx/types/webapi-v2';
import { SbxRecordListModalService } from '@/shared/sbx-record-list-modal/sbx-record-list-modal.service';

@Component({
  selector: 'sbx-list',
  templateUrl: './sbx-list.component.html',
})
export class SbxListComponent extends FieldType<FieldTypeConfig> implements OnInit {
  value;
  type: string;
  error: string;

  constructor(
    @Inject(SbxRecordListModalService)
    public sbxRecordListModalService: SbxRecordListModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.to.valueType.type === 'record') {
      this.type = 'listRecord';
      const recordKeys = Object.keys(Object.assign({}, ...this.formControl.value));
      const filteredFields = this.to.valueType.templateOptions.valueSchema.filter(
        (field) => recordKeys.includes(field.key),
      );
      const listForm: GenericViewForm = {
        name: this.field.key.toString(),
        description: {
          fields: filteredFields,
          label: this.to?.label,
        },
        model: this.formControl.value,
      };
      this.value = listForm;
    }
    if (this.to.valueType.type === 'stakeholder') {
      this.type = 'listStakeholder';
      this.value = this.formControl.value;
    }
  }

  async openRecordListModal(field) {
    try {
      const data = {
        modalHeader: field.description.label,
        form: field,
      };
      await this.sbxRecordListModalService.open(data).result;
    } catch (e) {
      if (e?.userCancelled) {
        return;
      }
      this.error = e.message;
    }
  }
}
