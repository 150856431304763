import { NgModule } from '@angular/core';
import { SbxRecordListModalComponent } from './sbx-record-list-modal.component';
import { SbxIconModule } from '@/shared/sbx-icon/sbx-icon.module';
import { CommonModule } from '@angular/common';
import { SbxModalModule } from '@/shared/sbx-modal/sbx-modal.module';
import { SbxAlertBannerModule } from '@/shared/sbx-alert-banner/sbx-alert-banner.module';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';
import { SbxFormViewModule } from '@/shared/sbx-form-view/sbx-form-view.module';

@NgModule({
  imports: [
    CommonModule,
    SbxIconModule,
    SbxModalModule,
    SbxAlertBannerModule,
    SbxButtonModule,
    SbxFormViewModule,
  ],
  declarations: [SbxRecordListModalComponent],
  exports: [SbxRecordListModalComponent],
})
export class SbxRecordListModalModule {}
