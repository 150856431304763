
<div class="sbx-form-view-field" [ngClass]="[fieldClasses, 'sbx-subfield-' + subfield]">
  <div class="sbx-form-view-key" [ngClass]="keyClasses" *ngIf="!config.hideFieldKeys">
    <ng-container *ngIf="!help">
      {{ label }}
    </ng-container>
    <ng-container *ngIf="help">
      {{ label }}
      <sbx-popover [sbxPopoverContent]="popoverTemplate">
        <sbx-icon type="questionCircle" class="sbx-help-icon"></sbx-icon>
      </sbx-popover>
      <ng-template #popoverTemplate>
        <div class="sbx-form-view-popover">
          <div *ngIf="config.showHelpPopoverLabel" class="sbx-form-view-popover-header">
            {{ label }}
          </div>
          <div class="sbx-form-view-popover-body">
            {{ help }}
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>

  <div class="sbx-form-view-value" [ngClass]="valueClasses">
    <ng-container #fieldComponent></ng-container>
  </div>
</div>
