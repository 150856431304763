<div class="sbx-content-section">
  <img
    [src]="sbxUrlService.assetUrl('img/fidelity/expired-error.svg')"
    alt="permission-error"
  />

  <span class="sbx-content-section-title">Link Not Available</span>

  <p class="sbx-content-section-error-message">
    Sorry, this link is not available. Please contact Support at
    <a href="mailto:support.fps@fmr.com">support.fps&#64;fmr.com</a> for assistance.
  </p>
</div>
