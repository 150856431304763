import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sbx-date',
  templateUrl: './sbx-date.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class SbxDateComponent extends FieldType<FieldTypeConfig> implements OnInit {
  format: string;
  ngOnInit(): void {
    this.format = this.to.config.dateFormat;
    if (this.to.subtype === 'onlyBirthday') {
      this.format = 'MMMM d';
    }
  }
}
