import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'sbx-measurement-textline',
  templateUrl: './sbx-measurement-textline.component.html',
})
export class SbxMeasurementTextlineComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  value: string;

  ngOnInit(): void {
    if (this.to.precision) {
      const fmt = `0.1-${this.to.precision}`;
      this.value = formatNumber(this.formControl.value, 'en', fmt) + this.to.postfix;
    } else {
      this.value = this.formControl.value + ' ' + this.to.postfix;
    }
  }
}
