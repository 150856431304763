<ng-container *ngIf="type === 'listDictionary'">
  <ng-container *ngFor="let entry of formControl.value">
    {{ entry.key }}:
    <sbx-button
      icon="show"
      title="Show Value"
      (click)="openTableModal(entry)"
    ></sbx-button>
  </ng-container>
</ng-container>

<ng-container *ngIf="type !== 'listDictionary'">
  {{ formControl.value }}
</ng-container>
