import { ConfigOption } from '@ngx-formly/core';
import { SbxFormViewFieldComponent } from './wrappers/sbx-form-view-field.component';
import { SbxStringTextlineComponent } from './fields/sbx-string-textline.component';
import { SbxNumberTextlineComponent } from './fields/sbx-number-textline.component';
import { SbxMeasurementTextlineComponent } from './fields/sbx-measurement-textline.component';
import { SbxBoolComponent } from './fields/sbx-bool.component';
import { SbxEnumComponent } from './fields/sbx-enum.component';
import { SbxDateComponent } from './fields/sbx-date.component';
import { SbxDatetimeComponent } from './fields/sbx-datetime.component';
import { SbxStakeholderComponent } from './fields/sbx-stakeholder.component';
import { SbxListComponent } from './fields/sbx-list.component';
import { SbxDictionaryComponent } from './fields/sbx-dictionary.component';
import { SbxDocumentReferenceComponent } from './fields/sbx-document-reference.component';
import { SbxAddressComponent } from './fields/sbx-address.component';

const types = [
  {
    name: 'string-textline-view',
    component: SbxStringTextlineComponent,
    wrappers: ['form-view-field'],
  },
  {
    name: 'text-view',
    component: SbxStringTextlineComponent,
    wrappers: ['form-view-field'],
  },
  {
    name: 'number-textline-view',
    component: SbxNumberTextlineComponent,
    wrappers: ['form-view-field'],
  },
  {
    name: 'measurement-textline-view',
    component: SbxMeasurementTextlineComponent,
    wrappers: ['form-view-field'],
  },
  {
    name: 'bool-checkbox-view',
    component: SbxBoolComponent,
    wrappers: ['form-view-field'],
  },
  {
    name: 'bool-radios-view',
    component: SbxBoolComponent,
    wrappers: ['form-view-field'],
  },
  {
    name: 'enum-radios-view',
    component: SbxEnumComponent,
    wrappers: ['form-view-field'],
  },
  {
    name: 'enum-dropdown-view',
    component: SbxEnumComponent,
    wrappers: ['form-view-field'],
  },
  {
    name: 'date-view',
    component: SbxDateComponent,
    wrappers: ['form-view-field'],
  },
  {
    name: 'datetime-view',
    component: SbxDatetimeComponent,
    wrappers: ['form-view-field'],
  },
  {
    name: 'stakeholder-view',
    component: SbxStakeholderComponent,
    wrappers: ['form-view-field'],
  },
  {
    name: 'list-view',
    component: SbxListComponent,
    wrappers: ['form-view-field'],
  },
  {
    name: 'dictionary-view',
    component: SbxDictionaryComponent,
    wrappers: ['form-view-field'],
  },
  {
    name: 'document-reference-view',
    component: SbxDocumentReferenceComponent,
    wrappers: ['form-view-field'],
  },
  {
    name: 'address-view',
    component: SbxAddressComponent,
    wrappers: ['form-view-field'],
  },
];

const wrappers = [
  {
    name: 'form-view-field',
    component: SbxFormViewFieldComponent,
  },
];

export const SbxFormViewConfig: ConfigOption = {
  types,
  wrappers,
};
