import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { IFormViewConfig } from '../sbx-form-view.component';

@Component({
  selector: 'sbx-form-view-field',
  templateUrl: './sbx-form-view-field.component.html',
  styleUrls: ['./sbx-form-view-field.component.scss'],
})
export class SbxFormViewFieldComponent extends FieldWrapper implements OnInit {
  fieldClasses: string;
  keyClasses: string;
  valueClasses: string;
  config: IFormViewConfig;
  label: string;
  help: string;
  subfield: number;

  constructor() {
    super();
  }

  ngOnInit() {
    this.label = this.to.label;
    this.help = this.to.help;
    this.config = this.to.config;
    this.subfield = this.to.subfield;
    this.setClasses();
  }

  setClasses() {
    this.fieldClasses = `sbx-form-view-field-${this.config?.format}`;
    this.keyClasses = `sbx-form-view-key-${this.config?.format}`;
    this.valueClasses = `sbx-form-view-value-${this.config?.format}`;
  }
}
