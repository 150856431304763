import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { formatCurrency, formatNumber } from '@angular/common';

@Component({
  selector: 'sbx-number-textline',
  templateUrl: './sbx-number-textline.component.html',
})
export class SbxNumberTextlineComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  value: string;

  ngOnInit(): void {
    if (this.to.prefix) {
      const fmt = this.to.precision ? `0.2-${this.to.precision}` : '0.0';
      this.value = formatCurrency(
        this.formControl.value,
        'en',
        this.to.prefix.text || this.to.prefix,
        null,
        fmt,
      );
    } else {
      this.value = formatNumber(this.formControl.value, 'en');
    }
  }
}
