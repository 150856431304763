import { NgModule } from '@angular/core';
import { SbxTableModalComponent } from './sbx-table-modal.component';
import { SbxIconModule } from '@/shared/sbx-icon/sbx-icon.module';
import { CommonModule } from '@angular/common';
import { SbxModalModule } from '@/shared/sbx-modal/sbx-modal.module';
import { SbxTableModule } from '@/shared/sbx-table/sbx-table.module';
import { SbxAlertBannerModule } from '@/shared/sbx-alert-banner/sbx-alert-banner.module';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';

@NgModule({
  imports: [
    CommonModule,
    SbxIconModule,
    SbxModalModule,
    SbxAlertBannerModule,
    SbxButtonModule,
    SbxTableModule,
  ],
  declarations: [SbxTableModalComponent],
  exports: [SbxTableModalComponent],
})
export class SbxTableModalModule {}
