<sbx-modal-header>
  <div class="sbx-table-modal-header">
    <h4 class="modal-title">{{ modalHeader }}</h4>
  </div>
</sbx-modal-header>
<sbx-modal-content>
  <sbx-alert-banner
    *ngIf="errorMsg"
    [error]="true"
    [customText]="errorMsg"
  ></sbx-alert-banner>
  <sbx-table class="sbx-table-modal-table" [columns]="columns" [data]="tableData">
  </sbx-table>
</sbx-modal-content>