import { Component, ViewChild, ViewContainerRef, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SbxModalService } from '../sbx-modal/sbx-modal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { SbxHttpClient } from '@/core/http';
import { SbxFormModalModel } from '@/shared/sbx-form-modal/sbx-form-modal.model';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'sbx-form-modal',
  templateUrl: './sbx-form-modal.component.html',
  styleUrls: ['sbx-form-modal.component.scss'],
})
export class SbxFormModalComponent implements OnInit, SbxFormModalModel {
  @ViewChild('container', { read: ViewContainerRef, static: true }) containerRef;

  url = null;
  saveUrl = null;
  // This was introduced because of fund project. Can be used to override api version.
  urlVersion = '2';
  updateUrl = null;
  documentReferenceUploadUrl = null;
  title = '';
  cancelButtonTitle = 'Cancel';
  closeButtonTitle = 'Close';
  okButtonTitle = 'Save';
  topMessageTemplate = null;
  topMessageText = '';
  topMessageTemplateContext = null;
  topInfoMessageText = '';
  bottomMessageTemplate = null;
  bottomMessageText = '';
  bottomMessageTemplateContext = null;
  config = {};
  readOnly = false;

  formName = '';
  form: any = new FormGroup({});
  model: any = {};
  templateData: any = {};
  formFields: Observable<FormlyFieldConfig[]> | FormlyFieldConfig[] | null = null;
  loading = false;
  errors = {};
  globalError = null;
  fixedHeight = false;
  saving = false;

  constructor(
    public sbxModalService: SbxModalService,
    public activeModal: NgbActiveModal,
    private sbxHttp: SbxHttpClient,
  ) {}

  ngOnInit() {
    if (this.url) {
      this.sbxHttp
        .entity(this.urlVersion)
        .get(this.url)
        .subscribe(
          (res: any) => {
            this.formName = res.name;
            this.title = res.title;
            this.topMessageText = res.topMessageText;
            this.topInfoMessageText = res.topInfoMessageText;
            if (res.topMessageTemplateContext) {
              this.topMessageTemplateContext = res.topMessageTemplateContext;
            }
            this.bottomMessageText = res.bottomMessageText;
            this.formFields = of(res.description.fields);
            this.updateUrl = res.description.updateUrl;
            this.documentReferenceUploadUrl = res.description.uploadUrl;
            this.saveUrl = res.description.saveUrl;
            Object.assign(this.model, res.model);
            Object.assign(this.templateData, res.templateData);
          },
          ({ error }) => {
            this.globalError = error.message;
          },
        );
    }
  }

  handleSave() {
    this.globalError = null;

    if (!this.form.valid) {
      return;
    }

    const url = this.saveUrl ? this.saveUrl : this.url;

    if (this.form.valid && url) {
      this.loading = true;
      this.sbxHttp
        .entity(this.urlVersion)
        .post(url, {
          params: this.model,
        })
        .subscribe(
          (res: any) => {
            this.loading = false;
            this.sbxModalService.close(res);
          },
          (errors) => {
            this.loading = false;
            this.handleError(errors.error);
          },
        );
    } else {
      this.sbxModalService.close(this.model);
    }
  }

  handleError(error) {
    let globalError;

    // v1 api error message handling
    if (typeof error === 'string') {
      globalError = error;
    } else if (!error?.message) {
      globalError = 'Could not update.';
    } else if (typeof error.message === 'string') {
      globalError = error.message;
    } else {
      this.errors = error.message;
      globalError = 'Please correct the form errors below.';
      if (error.message.__form__) {
        globalError = error.message.__form__;
      }
    }
    this.globalError = globalError;
  }

  // Empty function implementation which can be overridden using sbxFormModalService
  // eslint-disable-next-line @typescript-eslint/no-empty-function,no-empty-function
  handleModelChange(event: any) {}
}
