/**
 * @description
 * This handler is used on the recieving end of all ajax requests and is
 * designed to automatically redirect the user for "non-exceptional" responses
 * (such as forbidden).
 *
 * @param {object} window The window object.
 * @param {number} status The status code of the response.
 * @param {object} data The response body data.
 *
 * @returns {boolean} If it returns true, the handler is doing a redirect and the
 *   caller should end control flow of the program.
 */
export default function sbAjaxRedirectHandler({ location }, status, data, context) {
  const { hash, pathname, search } = location;
  const camefrom = encodeURIComponent(`${pathname}${search || ''}${hash || ''}`);
  const entityUrlPart = context?.entity ? `/${context?.entity}` : '';

  if (status === 403) {
    location.replace(`/spa${entityUrlPart}/forbidden`);
    return true;
  }
  if (status === 477) {
    location.replace(`/spa/expired`);
    return true;
  }
  if (status === 401) {
    // XXX: This should use angular router if available

    const params = new URLSearchParams(search);
    const loginParams = new URLSearchParams();
    // TODO: get rid of this encode/decode dance. Use URLSearchParams everywhere
    loginParams.append('camefrom', decodeURIComponent(camefrom));

    const target = '/spa/auth/login';
    const secToken = params.get('sec_token');
    if (secToken) {
      loginParams.append('sec_token', secToken);
    }
    location.replace(`${target}?${loginParams.toString()}`);
    return true;
  } else if (status === 478) {
    // Since "normal" 300 redirect status codes are transparent to JS, Shoobx has adopted
    // 478 as our substitute redirect code.
    const { redirectUrl } = data;
    if (location.href === redirectUrl) {
      // If we are currently at the same location as the redirect, just reload
      // so that the workitem load handler will not fire.
      location.reload();
      return true;
    }
    location.replace(redirectUrl);
    return true;
  } else if (status === 503) {
    location.replace(`/spa/error?camefrom=${camefrom}&initial=true`);
    return true;
  }
  return false;
} // end sbAjaxRedirectHandler

// this.appConfig.isSpa
