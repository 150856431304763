import { Injectable } from '@angular/core';
import { SbxModalService } from '@/shared/sbx-modal/sbx-modal.service';
import {
  SbxRecordListModalComponent,
  IRecordListModalData,
} from './sbx-record-list-modal.component';

@Injectable()
export class SbxRecordListModalService {
  constructor(private sbxModalService: SbxModalService) {}

  open(data: IRecordListModalData) {
    const modal = this.sbxModalService.open(SbxRecordListModalComponent, {
      size: 'lg',
      data,
    });

    return modal;
  }
}
