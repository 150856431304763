import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbxFormViewComponent } from './sbx-form-view.component';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';
import { SbxFormModule } from '../sbx-form/sbx-form.module';
import { SbxStakeholderCardModule } from '@/shared/sbx-stakeholder-card/sbx-stakeholder-card.module';
import { SbxRecordListModalService } from '@/shared/sbx-record-list-modal/sbx-record-list-modal.service';
import { SbxTableModalService } from '@/shared/sbx-table-modal/sbx-table-modal.service';
import { SbxPopoverModule } from '@/shared/sbx-popover/sbx-popover.module';
import { SbxIconModule } from '@/shared/sbx-icon/sbx-icon.module';
import { FormlyModule } from '@ngx-formly/core';
import { SbxFormViewConfig } from './sbx-form-view.config';
import { SbxStringTextlineComponent } from './fields/sbx-string-textline.component';
import { SbxNumberTextlineComponent } from './fields/sbx-number-textline.component';
import { SbxStakeholderComponent } from './fields/sbx-stakeholder.component';
import { SbxListComponent } from './fields/sbx-list.component';
import { SbxDictionaryComponent } from './fields/sbx-dictionary.component';
import { SbxAddressComponent } from './fields/sbx-address.component';
import { SbxFormViewFieldComponent } from './wrappers/sbx-form-view-field.component';

@NgModule({
  imports: [
    CommonModule,
    SbxFormModule,
    SbxButtonModule,
    SbxPopoverModule,
    SbxStakeholderCardModule,
    SbxIconModule,
    FormlyModule.forRoot(SbxFormViewConfig),
  ],
  declarations: [
    SbxFormViewComponent,
    SbxFormViewFieldComponent,
    SbxStringTextlineComponent,
    SbxNumberTextlineComponent,
    SbxStakeholderComponent,
    SbxListComponent,
    SbxDictionaryComponent,
    SbxAddressComponent,
  ],
  exports: [SbxFormViewComponent],
  providers: [SbxRecordListModalService, SbxTableModalService],
})
export class SbxFormViewModule {
  static entryComponents = [SbxFormViewComponent];
  static forRoot(): ModuleWithProviders<SbxFormViewModule> {
    return {
      ngModule: SbxFormViewModule,
    };
  }
}
