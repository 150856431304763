import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sbx-bool',
  templateUrl: './sbx-bool.component.html',
})
export class SbxBoolComponent extends FieldType<FieldTypeConfig> implements OnInit {
  value: string;

  ngOnInit(): void {
    this.value = this.formControl.value ? 'Yes' : 'No';
  }
}