import { Component, OnInit, Inject } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { SbxTableModalService } from '@/shared/sbx-table-modal/sbx-table-modal.service';

@Component({
  selector: 'sbx-dictionary',
  templateUrl: './sbx-dictionary.component.html',
})
export class SbxDictionaryComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  type: string;
  error: string;

  constructor(
    @Inject(SbxTableModalService)
    public sbxTableModalService: SbxTableModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.to.valueType.type === 'list') {
      this.type = 'listDictionary';
    }
  }

  async openTableModal(listData) {
    try {
      const data = {
        modalHeader: listData.key,
        modalData: [listData],
      };
      await this.sbxTableModalService.open(data).result;
    } catch (e) {
      if (e?.userCancelled) {
        return;
      }
      this.error = e.message;
    }
  }
}
