<ng-container *ngIf="type === 'listRecord'">
  <sbx-button
    icon="show"
    title="Show Value"
    (click)="openRecordListModal(value)"
  ></sbx-button>
</ng-container>

<ng-container *ngIf="type === 'listStakeholder'">
  <div *ngFor="let item of value">
    {{ item.sh.fullName }}
  </div>
</ng-container>
