import { Injectable } from '@angular/core';
import { SbxModalService } from '@/shared/sbx-modal/sbx-modal.service';
import {
  SbxTableModalComponent,
  ITableModalData,
} from './sbx-table-modal.component';

@Injectable()
export class SbxTableModalService {
  constructor(private sbxModalService: SbxModalService) {}

  open(data: ITableModalData) {
    const modal = this.sbxModalService.open(SbxTableModalComponent, {
      size: 'md',
      data,
    });

    return modal;
  }
}
