import { Component, OnInit } from '@angular/core';
import { SbxModalService } from '@/shared/sbx-modal/sbx-modal.service';
import { ISbxTableColumns } from '@/shared/sbx-table/sbx-table.component';

interface ITableColumn {
  key: string;
  value: Array<string>;
}

export interface ITableModalData {
  modalHeader?: string;
  modalData: ITableColumn[];
}

@Component({
  selector: 'sbx-table-modal',
  templateUrl: './sbx-table-modal.component.html',
  styleUrls: ['sbx-table-modal.component.scss'],
})
export class SbxTableModalComponent implements OnInit {
  errorMsg: string;
  modalHeader: string;
  columns: ISbxTableColumns = [];
  modalData: ITableColumn[];
  tableData = [];

  constructor(public sbxModalService: SbxModalService) {}

  ngOnInit() {
    this.columns = this.modalData.map(row => ({key: row.key}))
    this.modalData.forEach(row => {
      row.value.forEach(value => {
        this.tableData.push(
          {[row.key]: value}
        )
      })
    })
  }
}
