<sbx-modal-header>
  <div class="sbx-record-list-modal-header">
    <h4 class="modal-title">{{ modalHeader }}</h4>
  </div>
</sbx-modal-header>
<sbx-modal-content>
  <sbx-alert-banner
    *ngIf="errorMsg"
    [error]="true"
    [customText]="errorMsg"
  ></sbx-alert-banner>
  <ng-container *ngFor="let form of forms; index as i">
    <sbx-form-view
      [form]="form"
      [config]="{
        format: 'horizontal',
        hideFieldKeys: i !== 0
      }"
    ></sbx-form-view>
  </ng-container>
</sbx-modal-content>
