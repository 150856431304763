import { Component, ViewChild, OnInit, TemplateRef } from '@angular/core';
import { SbxModalService } from '@/shared/sbx-modal/sbx-modal.service';
import { GenericViewForm } from '@shoobx/types/webapi-v2';

interface GenericViewFormModalArray extends GenericViewForm {
  model: Array<object>;
}

export interface IRecordListModalData {
  modalHeader?: string;
  form?: GenericViewFormModalArray;
}

@Component({
  selector: 'sbx-record-list-modal',
  templateUrl: './sbx-record-list-modal.component.html',
  styleUrls: ['sbx-record-list-modal.component.scss'],
})
export class SbxRecordListModalComponent implements OnInit {
  @ViewChild('modalTemplate', { static: true }) modalTemplate: TemplateRef<string>;

  errorMsg: string;
  modalHeader: string;
  form: GenericViewFormModalArray;
  forms: GenericViewFormModalArray[] = [];

  constructor(public sbxModalService: SbxModalService) {}

  ngOnInit() {
    let form;
    this.form.model.forEach((item) => {
      form = { ...this.form };
      form.model = item;
      this.forms.push(form);
    });
  }
}
