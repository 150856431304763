import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sbx-enum',
  templateUrl: './sbx-enum.component.html',
})
export class SbxEnumComponent extends FieldType<FieldTypeConfig> implements OnInit {
  value: string;

  ngOnInit(): void {
    this.value = this.to.enumVocab.find(
      (option) => option.value === this.formControl.value,
    )?.label;
  }
}
