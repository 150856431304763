import {
  Component,
  Input,
  Output,
  EventEmitter,
  DestroyRef,
  inject,
  OnChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SbxHttpClient } from '@/core/http';
import { catchError, EMPTY } from 'rxjs';
import { GenericViewForm } from '@shoobx/types/webapi-v2';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';

/**
 *  Form View Component's purpose is to display the values of fields in a form.
 *  It handles various types of fields and provides different configuration options as
 *  specified in IFormViewConfig. If certain fields need to be handled outside this generic
 *  component they can be passed in skipFields.
 *  Example:
Component Template:
    <sbx-form-view
      [form]="form"
      [config]="{
        dateFormat: 'MMMM d, y',
        addressInline: true,
        showHelpPopoverLabel: true,
        showStakeholderCard: false
      }"
    ></sbx-form-view>

    <sbx-form-view
      [form]="form"
      [skipFields]="skipFields"
      [config]="{
        format: 'inline',
        dateFormat: 'MMMM dd, y'
      }"
      (dataLoaded)="formViewDataLoaded($event)"
    ></sbx-form-view>
 */

export const ERROR_MESSAGE = 'An error occurred while displaying form data.';

export interface IAddressResponse {
  uploadUrl?: string | null;
  updateUrl?: string | null;
  fields: FormlyFieldConfig[];
}

export interface IFormViewConfig {
  format?: 'inline' | 'vertical' | 'horizontal';
  dateFormat?: string;
  dateTimeFormat?: string;
  addressInline?: boolean;
  showStakeholderCard?: boolean;
  showHelpPopoverLabel?: boolean;
  hideFieldKeys?: boolean;
}

@Component({
  selector: 'sbx-form-view',
  templateUrl: './sbx-form-view.component.html',
  styleUrls: ['./sbx-form-view.component.scss'],
})
export class SbxFormViewComponent implements OnChanges {
  // For date and date time formats check https://angular.dev/api/common/DatePipe
  configDefault: IFormViewConfig = {
    dateFormat: 'MM/dd/y',
    dateTimeFormat: 'M/d/yy h:m a',
    format: 'vertical',
    hideFieldKeys: false,
    addressInline: false,
    showHelpPopoverLabel: false,
    showStakeholderCard: true,
  };

  @Input() form: GenericViewForm;
  @Input() skipFields: string[] = [];
  @Input() config: IFormViewConfig;
  @Output() dataLoaded = new EventEmitter();
  destroyRef = inject(DestroyRef);
  error: string;
  formClasses: string;
  fieldClasses: string;
  keyClasses: string;
  valueClasses: string;
  formlyForm: FormGroup;
  formFields: FormlyFieldConfig[];
  filteredFields: FormlyFieldConfig[];
  loading = false;

  constructor(private sbxHttp: SbxHttpClient) {}

  ngOnChanges() {
    this.formlyForm = new FormGroup({});
    this.config = { ...this.configDefault, ...this.config };
    if (!this.config.addressInline) {
      // eslint-disable-next-line dot-notation
      this.form?.description['fields'].map((field, index) => {
        if (field.type === 'address') {
          this.loading = true;
          this.getAddressSchema(field, index);
        }
      });
    }
    this.setClasses();
    this.handleFields();
  }

  getValue(key, model) {
    return key?.split('.')?.reduce((acc, cur) => acc[cur], model);
  }

  handleFields() {
    this.filteredFields = [];
    // eslint-disable-next-line dot-notation
    this.form?.description['fields'].forEach((field) => {
      field.templateOptions.config = this.config;
      if (!field.type.includes('-view')) {
        field.type = field.type + '-view';
      }
      if (!this.skipFields.includes(field.key)) {
        this.filteredFields.push(field);
      }
    });
    this.formFields = [
      {
        fieldGroup: this.filteredFields,
        fieldGroupClassName: this.formClasses,
      },
    ];
  }

  getAddressSchema(field, index) {
    const country = this.getValue(field.key, this.form.model)?.country || 'US';
    this.sbxHttp
      .entity('2')
      .get('addressSchema/' + country)
      .pipe(
        catchError((e) => {
          this.dataLoaded.emit({ error: e?.error?.message || ERROR_MESSAGE });
          return EMPTY;
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((res: IAddressResponse) => {
        res.fields.map(
          (addressField) => (addressField.key = `${field.key}.${addressField.key}`),
        );
        // eslint-disable-next-line dot-notation
        this.form.description['fields'].splice(index, 1, ...res.fields);
        this.handleFields();
        this.loading = false;
      });
  }

  setClasses() {
    this.formClasses = `sbx-form-view-${this.config.format}`;
    this.fieldClasses = `sbx-form-view-field-${this.config.format}`;
    this.keyClasses = `sbx-form-view-key-${this.config.format}`;
    this.valueClasses = `sbx-form-view-value-${this.config.format}`;
  }
}
