import { SbxUrlService } from '@/core/url/url.service';
import { SbxButtonModule } from '@/shared/sbx-button/sbx-button.module';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'sbx-expired-access-page',
  templateUrl: './sbx-expired-access-page.component.html',
  styleUrls: ['./sbx-expired-access-page.component.scss'],
  standalone: true,
  imports: [CommonModule, SbxButtonModule],
})
export class SbxExpiredAccessPageComponent {
  constructor(public readonly sbxUrlService: SbxUrlService) {}
}
